<template>
  <div class="request-card">
    <div class="request-card__top">
      <div class="request-card__service">
        <span>
          <img src="/img/icons/Google.svg" alt="" />
        </span>
        <div>
          <span> Google </span>
          <small>allison@gmail.com</small>
        </div>
      </div>
      <span class="progress-bar" ref="progressBarElement"></span>
    </div>
    <div class="request-card__bottom">
      <div class="request-card__status">
        <span v-if="progress < 1">
          Pending
          <img src="/img/loading/spinner.gif" alt="" />
        </span>
        <span v-else>
          Completed
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
              fill="#41D3BD"
            />
          </svg>
        </span>
      </div>
      <span> Started 23.05.2023 </span>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ProgressBar from "progressbar.js/dist/progressbar";
const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 1,
  },
});

const progress = ref(0);
const progressBarElement = ref(null);

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
onMounted(() => {
  const rndInt = randomIntFromInterval(50, 500);
  var bar = new ProgressBar.Circle(progressBarElement.value, {
    svgStyle: {
      strokeLinecap: "round",
      width: "48px",
      height: "48px",
    },
    color: "#AA87FF",
    trailColor: "#2B2B4F1A",
    trailWidth: 8,
    duration: 1400,
    easing: "easeOut",
    strokeWidth: 8,
    from: { color: "#AA87FF", a: 0 },
    to: { color: "#AA87FF", a: 1 },
  });

  const interval = setInterval(() => {
    if (progress.value <= 1) {
      progress.value += 0.01;
      bar.set(progress.value);
      bar.setText(Math.round(progress.value * 100) + "%");
    } else {
      clearInterval(interval);
    }
  }, rndInt);
});
// Circle
</script>
