<template>
  <span>{{ current_value }}</span>
</template>
<script setup>
import { ref, onMounted } from "vue";
const props = defineProps({
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  speed: {
    type: Number,
    required: false,
    default: 10,
  },
  startValue: {
    type: Number,
    required: false,
    default: 0,
  },
  endValue: {
    type: Number,
    required: true,
  },
});
// define variables
const count = ref(props.startValue);
const current_value = ref(0);
onMounted(() => {
  // function to add commas to number
  function addCommas(num) {
    var str = num.toString();
    var result = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }

  // function to update counter
  function updateCounter() {
    count.value += props.step;
    current_value.value = addCommas(count.value);
    if (count.value >= props.endValue) {
      count.value = props.endValue;
      current_value.value = addCommas(props.endValue);
      clearInterval(interval);
    }
  }
  // start counter animation
  const interval = setInterval(updateCounter, props.speed);
});
</script>
