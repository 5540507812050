<template>
  <AppLayoutDefault>
    <template v-slot:title>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.7253 22.7839C28.7946 22.7857 27.8738 22.9747 27.0176 23.3396C26.1613 23.7045 25.3873 24.2379 24.7413 24.908L17.1196 21.0901C17.1854 20.7327 17.2226 20.3706 17.2307 20.0073C17.2226 19.6439 17.1854 19.2818 17.1196 18.9244L24.7413 15.1066C25.8628 16.2634 27.3517 16.9941 28.9529 17.1734C30.5541 17.3526 32.1678 16.9694 33.5173 16.0892C34.8668 15.209 35.8682 13.8868 36.3496 12.3493C36.8311 10.8117 36.7627 9.15454 36.1562 7.66188C35.5496 6.16922 34.4428 4.93403 33.0253 4.16808C31.6078 3.40212 29.968 3.15311 28.3871 3.46373C26.8061 3.77434 25.3825 4.62524 24.3601 5.87055C23.3378 7.11586 22.7806 8.67801 22.7838 10.2892C22.7919 10.6525 22.8291 11.0146 22.8949 11.3721L15.2732 15.1899C14.3128 14.1936 13.0767 13.5071 11.7233 13.2185C10.37 12.9298 8.96132 13.0523 7.67809 13.57C6.39485 14.0878 5.29565 14.9773 4.52161 16.1243C3.74756 17.2713 3.33398 18.6235 3.33398 20.0073C3.33398 21.391 3.74756 22.7432 4.52161 23.8902C5.29565 25.0372 6.39485 25.9267 7.67809 26.4445C8.96132 26.9623 10.37 27.0847 11.7233 26.7961C13.0767 26.5074 14.3128 25.8209 15.2732 24.8247L22.8949 28.6425C22.8291 28.9999 22.7919 29.362 22.7838 29.7253C22.7838 31.0982 23.191 32.4403 23.9537 33.5818C24.7164 34.7233 25.8005 35.6131 27.0689 36.1384C28.3373 36.6638 29.733 36.8013 31.0795 36.5334C32.4261 36.2656 33.6629 35.6045 34.6337 34.6337C35.6045 33.6629 36.2656 32.4261 36.5334 31.0796C36.8013 29.733 36.6638 28.3373 36.1384 27.069C35.613 25.8006 34.7233 24.7164 33.5818 23.9537C32.4403 23.191 31.0982 22.7839 29.7253 22.7839Z"
          fill="url(#paint0_linear_1524_2075)"
        />
        <defs>
          <linearGradient id="paint0_linear_1524_2075" x1="36.6668" y1="36.6668" x2="3.33348" y2="3.334" gradientUnits="userSpaceOnUse">
            <stop stop-color="#AA87FF" />
            <stop offset="1" stop-color="#7A96FF" />
          </linearGradient>
        </defs>
      </svg>
      <h3>Data Sharing</h3>
    </template>
    <template v-slot:content>
      <div class="breadcrumbs">
        <ul>
          <li><routerLink to="/">Home</routerLink></li>
          <li>/</li>
          <li>Data Sharing</li>
        </ul>
      </div>
      <div class="request-dashboard">
        <div class="request-dashboard">
          <div class="request-dashboard__top">
            <div class="request-dashboard__title">
              <h3>Data Sharing</h3>
            </div>
            <p>Decide which categories of data to enable sharing for</p>
          </div>
          <div class="request-dashboard__body">
            <div class="request-dashboard__legend">
              Choose Data Categories
            </div>
            <div class="integration-card-list integration-card-list--1">
              <div class="integration-card integration-card--1">
                <div>
                  <span>
                    <img src="/img/icons/Location.svg" alt="" />
                  </span>
                  <b>Location Data</b>
                </div>
                <div>
                  <span>$5.32</span>
                  <label class="switch">
                    <input type="checkbox" checked/>
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="integration-card integration-card--1">
                <div>
                  <span>
                    <img src="/img/icons/Health.svg" alt="" />
                  </span>
                  <b>Health Data</b>
                </div>
                <div>
                  <span>$7.61</span>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="integration-card integration-card--1">
                <div>
                  <span>
                    <img src="/img/icons/Browsing.svg" alt="" />
                  </span>
                  <b>Browsing Data</b>
                </div>
                <div>
                  <span>$3.18</span>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="integration-card integration-card--1">
                <div>
                  <span>
                    <img src="/img/icons/Wallet.svg" alt="" />
                  </span>
                  <b>Finance Data</b>
                </div>
                <div>
                  <span>$6.89</span>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="request-dashboard__legend">Recent Transaction</div>
            <div class="tab-wrapper">
              <div class="tab-list">
                <div class="tab-checkbox tab-checkbox--1">
                  <label><input type="radio" name="filter-hp" value="1" checked /><span>Location Data</span></label>
                </div>
                <div class="tab-checkbox tab-checkbox--1">
                  <label><input type="radio" name="filter-hp" value="2" /><span>Health Data</span></label>
                </div>
                <div class="tab-checkbox tab-checkbox--1">
                  <label><input type="radio" name="filter-hp" value="3" /><span>Finance Data</span></label>
                </div>
                <div class="tab-checkbox tab-checkbox--1">
                  <label><input type="radio" name="filter-hp" value="3" /><span>Browsing Data</span></label>
                </div>
              </div>
              <div class="tab-content">
                <ul class="recent-tn-list">
                  <li class="recent-tn-item">
                    <span><img src="/img/icons/pinx.svg" alt="" /></span>
                    <img src="/img/icons/Amazon.svg" alt="" />
                    <div>
                      <span> Amazon <b>$ 2.99</b></span>
                      <small>25.03.2023</small>
                    </div>
                  </li>
                  <li class="recent-tn-item">
                    <span><img src="/img/icons/pinx.svg" alt="" /></span>
                    <img src="/img/icons/Uber_24_24.svg" alt="" />
                    <div>
                      <span> Uber <b>$ 4.32</b></span>
                      <small>25.03.2023</small>
                    </div>
                  </li>
                  <li class="recent-tn-item">
                    <span><img src="/img/icons/pinx.svg" alt="" /></span>
                    <img src="/img/icons/Amazon.svg" alt="" />
                    <div>
                      <span> Amazon <b>$ 2.99</b></span>
                      <small>25.12.2022</small>
                    </div>
                  </li>
                  <li class="recent-tn-item">
                    <span><img src="/img/icons/pinx.svg" alt="" /></span>
                    <img src="/img/icons/Uber_24_24.svg" alt="" />
                    <div>
                      <span> Uber <b>$ 4.32</b></span>
                      <small>05.08.2022</small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Add component to show total payout -->
            <div class="request-dashboard__legend">Total Payout this month</div>
            <div class="request-dashboard__total-amount">
              <h3>$ 7.31</h3>
            </div>
            <!-- Add a graph to show the payout over the last 12 months -->
            <div class="card-chart">
                      <div class="card-chart__stats">
                        <span>Average $ payout per Month</span>
                        <b><CounterUp :endValue="5.67" :step="5" :speed="1" /></b>
                      </div>
                      <div class="card-chart__value">
                        <canvas class="fit-chart"></canvas>
                      </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayoutDefault>
</template>

<script setup>
import AppLayoutDefault from "../layouts/AppLayoutDefault.vue";
import RequestCard from "../components/RequestCard.vue";
import CounterUp from "../components/CounterUp.vue";



// Vue & Store
import { useHead } from "unhead";
import { onMounted } from "vue";
import { inject } from "vue";
// Libraries
import Chart from "chart.js/auto";

// Lifecycles
onMounted(() => {
  const chartLineColors = [["#41D3BD"]];
  const chartGradients = [
    ["rgba(252, 253, 253, 0)", "rgba(65, 211, 189, 0.5)"]
  ];

  const chartValues = [
    [...Array(16)].map((_) => Math.ceil(Math.random() * 200))
  ];

  const fitCharts = document.querySelectorAll(".fit-chart");
  fitCharts.forEach(function (canvas, indx) {
    // Set gradients
    var ctx = canvas.getContext("2d");
    var gradient = ctx.createLinearGradient(0, 80, 0, 0);
    gradient.addColorStop(0, chartGradients[indx][0]);
    gradient.addColorStop(1, chartGradients[indx][1]);

    // Set Charts
    new Chart(canvas, {
      type: "line",
      data: {
        labels: [...Array(16).keys()],
        datasets: [
          {
            label: "My First Dataset",
            data: chartValues[indx],
            fill: false,
            borderColor: chartLineColors[indx],
            backgroundColor: gradient,
            tension: 0.07,
            borderCapStyle: false,
            borderWidth: 1.5,
            fill: true,
            drawActiveElementsOnTop: false,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
          },
          x: {
            display: false,
          },
        },
      },
    });
  });

  // Injects plugins
  inject("sliderImitate")();
});

const head = useHead({
  title: `Datapods — Data Sharing`,
});
</script>
