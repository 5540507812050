<template>
  <AppLayoutDefault>
    <template v-slot:title>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.4794 6.54647C37.2611 4.72844 35.456 2.75541 33.4845 3.49171C33.4683 3.49774 33.4523 3.50416 33.4364 3.51098L4.51253 15.974C4.49488 15.9816 4.47745 15.9897 4.46024 15.9983C3.60972 16.4218 3.24342 17.2914 3.35144 18.0803C3.46244 18.8908 4.07526 19.6292 5.05492 19.7696L5.05718 19.7699L18.4606 21.7328L20.2993 34.9422L20.3 34.9465C20.4411 35.9305 21.1865 36.5383 21.9939 36.6479C22.7789 36.7545 23.6508 36.3941 24.0774 35.5443L24.0877 35.5229C24.0911 35.5161 24.0943 35.5091 24.0974 35.5022C24.0997 35.4969 24.1021 35.4916 24.1043 35.4863L36.4794 6.54647Z"
          fill="url(#paint0_linear_1524_1810)"
        />
        <defs>
          <linearGradient id="paint0_linear_1524_1810" x1="36.6663" y1="36.6661" x2="3.33374" y2="3.33276" gradientUnits="userSpaceOnUse">
            <stop stop-color="#AA87FF" />
            <stop offset="1" stop-color="#7A96FF" />
          </linearGradient>
        </defs>
      </svg>
      <h3>Location Data</h3>
    </template>
    <template v-slot:content>
      <div class="breadcrumbs">
        <ul>
          <li><routerLink to="/">Dashboard</routerLink></li>
          <li>/</li>
          <li>Location Data</li>
        </ul>
      </div>
      <div class="location-dashboard">
        <div class="location-dashboard__top">
          <div>
            <b>Data Sources</b>
            <span>Select source to filter results</span>
          </div>
          <div class="source-check-list">
            <label class="source-check">
              <input type="checkbox" checked />
              <img src="/img/icons/Google.svg" alt="" />
              <span></span>
            </label>
            <label class="source-check">
              <input type="checkbox" checked />
              <img src="/img/icons/Facebook.svg" alt="" />
              <span></span>
            </label>
            <label class="source-check">
              <input type="checkbox" />
              <img src="/img/icons/Amazon.svg" alt="" />
              <span></span>
            </label>
            <label class="source-check">
              <input type="checkbox" />
              <img src="/img/icons/Netflix.svg" alt="" />
              <span></span>
            </label>
            <label class="source-check">
              <input type="checkbox" />
              <img src="/img/icons/Tesla.svg" alt="" />
              <span></span>
            </label>
          </div>
          <button @click="delete_confirm = true" class="btn">Request Deletion</button>
        </div>
        <Modal :class="{ 'is-open': delete_confirm }">
        <template v-slot:top>
          <span>Summary Request</span>
        </template>
        <template v-slot:body>
          <ul>
            <li><b>Age range</b> 21-40</li>
            <li><b>Gender</b> Male, Female, Other</li>
            <li><b>Location</b> Los Angeles, California</li>
          </ul>
        </template>
        <template v-slot:bottom>
          <button
            @click="
              summary = false;
              created = true;
            "
            class="btn"
          >
            Request
          </button>
        </template>
      </Modal>
        <div class="loc-card-list">
          <div class="card">
            <div class="card__top">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M9.69744 14.9241C9.78278 14.923 9.86536 14.8937 9.93229 14.8407C10.1596 14.6589 15.3793 10.4241 15.3793 7.34832C15.3793 5.84141 14.7806 4.39622 13.7151 3.33067C12.6495 2.26512 11.2044 1.6665 9.69744 1.6665C8.19053 1.6665 6.74534 2.26512 5.67979 3.33067C4.61424 4.39622 4.01563 5.84141 4.01562 7.34832C4.01563 10.4241 9.23532 14.6589 9.46259 14.8407C9.52952 14.8937 9.6121 14.923 9.69744 14.9241ZM9.69744 5.07559C10.072 5.07559 10.4382 5.18667 10.7497 5.39478C11.0611 5.60289 11.3039 5.89868 11.4472 6.24475C11.5906 6.59083 11.6281 6.97163 11.555 7.33902C11.4819 7.70641 11.3015 8.04388 11.0367 8.30875C10.7718 8.57362 10.4343 8.754 10.0669 8.82708C9.69954 8.90016 9.31874 8.86265 8.97266 8.71931C8.62659 8.57596 8.3308 8.33321 8.12269 8.02175C7.91458 7.71029 7.8035 7.34412 7.8035 6.96953C7.8035 6.46723 8.00304 5.9855 8.35823 5.63032C8.71341 5.27513 9.19514 5.07559 9.69744 5.07559Z"
                      fill="#2B2B4F"
                    />
                    <path
                      d="M2.5 17.1969C2.5 17.4983 2.61972 17.7873 2.83283 18.0004C3.04594 18.2136 3.33498 18.3333 3.63636 18.3333H15.7576C16.059 18.3333 16.348 18.2136 16.5611 18.0004C16.7742 17.7873 16.8939 17.4983 16.8939 17.1969C16.8939 16.8955 16.7742 16.6065 16.5611 16.3934C16.348 16.1803 16.059 16.0605 15.7576 16.0605H3.63636C3.33498 16.0605 3.04594 16.1803 2.83283 16.3934C2.61972 16.6065 2.5 16.8955 2.5 17.1969Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
                Detailed Location Data
              </span>
            </div>
            <div class="card__body">
              <div class="history">
                <span>Today, Tuesday, May 23, 2023.</span>
                <ul class="history-list">
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                </ul>
                <span>Yesterday, Tuesday, Apr 4, 2023.</span>
                <ul class="history-list">
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>9m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        San Francisco, United States
                        <small>5m ago</small>
                      </span>
                      <span> <img src="/img/icons/small/Chrome.svg" alt="" /><small>Signed in from Chrome (MacOS)</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>7m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        San Francisco, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/YouTube.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                </ul>
                <span>Monday, Apr 3, 2023.</span>
                <ul class="history-list">
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Los Angeles, United States
                        <small>15m ago</small>
                      </span>
                      <span> <img src="/img/icons/Google.svg" alt="" /><small>Authorization in account</small></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card__top">
              <span>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M13.3699 13.124H17.1551C17.197 13.1216 17.2369 13.1053 17.2683 13.0776C17.2998 13.0499 17.3211 13.0124 17.3288 12.9712C17.3288 12.9018 17.3288 12.8393 17.3288 12.7767V7.91496C17.3288 7.54655 17.1824 7.19324 16.9219 6.93273C16.6614 6.67223 16.3081 6.52588 15.9397 6.52588H12.6754C12.6293 6.52588 12.5851 6.54417 12.5526 6.57674C12.52 6.6093 12.5017 6.65346 12.5017 6.69951V6.87315C12.5017 7.33366 12.3188 7.77531 11.9932 8.10094C11.6675 8.42657 11.2259 8.6095 10.7654 8.6095C10.3049 8.6095 9.86321 8.42657 9.53758 8.10094C9.21195 7.77531 9.02901 7.33366 9.02901 6.87315V6.69951C9.02901 6.65346 9.01072 6.6093 8.97816 6.57674C8.94559 6.54417 8.90143 6.52588 8.85538 6.52588H6.22307C5.85466 6.52588 5.50134 6.67223 5.24084 6.93273C4.98033 7.19324 4.83398 7.54655 4.83398 7.91496V16.944C4.83398 17.3124 4.98033 17.6657 5.24084 17.9262C5.50134 18.1867 5.85466 18.3331 6.22307 18.3331H11.7794H11.9947C12.0383 18.3296 12.079 18.3098 12.1086 18.2776C12.1382 18.2454 12.1546 18.2032 12.1544 18.1594V14.3395C12.1544 14.0171 12.2825 13.708 12.5104 13.48C12.7384 13.2521 13.0475 13.124 13.3699 13.124ZM9.89719 14.8604H7.81357C7.67541 14.8604 7.54292 14.8055 7.44523 14.7078C7.34754 14.6101 7.29266 14.4776 7.29266 14.3395C7.29266 14.2013 7.34754 14.0688 7.44523 13.9711C7.54292 13.8734 7.67541 13.8186 7.81357 13.8186H9.89719C10.0353 13.8186 10.1678 13.8734 10.2655 13.9711C10.3632 14.0688 10.4181 14.2013 10.4181 14.3395C10.4181 14.4776 10.3632 14.6101 10.2655 14.7078C10.1678 14.8055 10.0353 14.8604 9.89719 14.8604ZM7.81357 11.7349C7.67541 11.7349 7.54292 11.6801 7.44523 11.5824C7.34754 11.4847 7.29266 11.3522 7.29266 11.214C7.29266 11.0759 7.34754 10.9434 7.44523 10.8457C7.54292 10.748 7.67541 10.6931 7.81357 10.6931H14.4117C14.5499 10.6931 14.6824 10.748 14.78 10.8457C14.8777 10.9434 14.9326 11.0759 14.9326 11.214C14.9326 11.3522 14.8777 11.4847 14.78 11.5824C14.6824 11.6801 14.5499 11.7349 14.4117 11.7349H7.81357Z"
                      fill="#2B2B4F"
                    />
                    <path
                      d="M16.5787 14.4647C16.6032 14.4394 16.6201 14.4078 16.6274 14.3734C16.6348 14.339 16.6324 14.3033 16.6204 14.2702C16.6068 14.2391 16.5845 14.2127 16.5561 14.1942C16.5277 14.1757 16.4945 14.1659 16.4606 14.166H13.3699C13.3239 14.166 13.2797 14.1843 13.2471 14.2169C13.2146 14.2494 13.1963 14.2936 13.1963 14.3397V17.4373C13.1962 17.4712 13.206 17.5044 13.2245 17.5328C13.243 17.5611 13.2694 17.5835 13.3005 17.597C13.3317 17.6102 13.3661 17.6139 13.3994 17.6077C13.4328 17.6016 13.4635 17.5858 13.488 17.5623L16.5787 14.4647Z"
                      fill="#2B2B4F"
                    />
                    <path
                      d="M9.9596 5.67169C9.99144 5.68492 10.0188 5.707 10.0385 5.73531C10.0582 5.76362 10.0694 5.79697 10.0707 5.83143V6.87324C10.0707 7.05745 10.1439 7.2341 10.2741 7.36436C10.4044 7.49461 10.5811 7.56778 10.7653 7.56778C10.9495 7.56778 11.1261 7.49461 11.2564 7.36436C11.3866 7.2341 11.4598 7.05745 11.4598 6.87324V5.83143C11.4597 5.79754 11.4695 5.76436 11.488 5.73597C11.5065 5.70759 11.5329 5.68524 11.564 5.67169C11.9443 5.51385 12.2693 5.24687 12.498 4.90446C12.7267 4.56205 12.8488 4.15956 12.8489 3.74781C12.8485 3.37097 12.7459 3.0013 12.552 2.67815C12.3581 2.35501 12.0802 2.0905 11.7479 1.91278C11.4156 1.73505 11.0413 1.65078 10.6649 1.66892C10.2885 1.68706 9.92411 1.80695 9.61044 2.01581C9.29678 2.22467 9.04562 2.51468 8.88373 2.85497C8.72183 3.19526 8.65525 3.57309 8.69108 3.94822C8.72691 4.32336 8.8638 4.68175 9.08718 4.98525C9.31056 5.28874 9.61207 5.52597 9.9596 5.67169Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
                Last Visited Places
              </span>
            </div>
            <div class="card__body">
              <div class="history">
                <span>Today, Tuesday, May 23, 2023.</span>
                <ul class="history-list">
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/House.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Home
                        <small>12:32</small>
                      </span>
                      <span>236 S Coronado St, Los Angeles, CA 90057, США</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Legacy Skate Shop
                        <small>12:32</small>
                      </span>
                      <span>2223 Beverly Blvd, Los Angeles, CA 90057, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Starbucks
                        <small>15:10</small>
                      </span>
                      <span>350 S Grand Ave, Los Angeles, CA 90071, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Country Ranch Market
                        <small>18:32</small>
                      </span>
                      <span>2800 Beverly Blvd, Los Angeles, CA 90057, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        2220 Arts + Archives
                        <small>19:20</small>
                      </span>
                      <span>2800 Beverly Blvd, Los Angeles, CA 90057, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Lake Street skatepark
                        <small>20:12</small>
                      </span>
                      <span>227 N Lake St, Los Angeles, CA 90026, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Prime Wealth Enterprise, LLC
                        <small>21:38</small>
                      </span>
                      <span>1911 W 2nd St #11, Los Angeles, CA 90057, USA</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/House.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Home
                        <small>22:52</small>
                      </span>
                      <span>236 S Coronado St, Los Angeles, CA 90057, США</span>
                    </div>
                  </li>
                  <li class="history-item">
                    <span>
                      <img src="/img/icons/pinx.svg" alt="" />
                    </span>
                    <div>
                      <span>
                        Lake Street skatepark
                        <small>20:12</small>
                      </span>
                      <span>227 N Lake St, Los Angeles, CA 90026, USA</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card__top">
              <span>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.5732 3.27299C18.964 2.36398 18.0615 1.37746 17.0757 1.74561C17.0676 1.74862 17.0596 1.75184 17.0517 1.75525L2.58976 7.98677C2.58094 7.99057 2.57222 7.99462 2.56362 7.9989C2.13835 8.21065 1.95521 8.64544 2.00922 9.03989C2.06472 9.44514 2.37113 9.81435 2.86096 9.88456L2.86209 9.8847L9.56381 10.8661L10.4831 17.4709L10.4835 17.473C10.554 17.965 10.9267 18.2689 11.3305 18.3237C11.723 18.377 12.1589 18.1968 12.3722 17.7719L12.3774 17.7612C12.379 17.7578 12.3806 17.7543 12.3822 17.7508C12.3834 17.7482 12.3845 17.7455 12.3857 17.7429L18.5732 3.27299Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>

                Location Heatmap
              </span>
            </div>
            <div class="card__body">
              <img src="/img/heatmap_large.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayoutDefault>
</template>

<script setup>
// Components
import AppLayoutDefault from "../layouts/AppLayoutDefault.vue";
// Vue & Store
import { useHead } from "unhead";
import { onMounted, ref } from "vue";
import Modal from "../components/Modal.vue";

const summary = ref(false);
const created = ref(false);

const head = useHead({
  title: `Datapods — Location`,
});
</script>
