<template>
  <AppLayoutDefault>
    <template v-slot:title>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1524_650)">
          <path
            d="M28.1253 3.72221C28.0323 3.62104 27.9097 3.5518 27.775 3.52437C27.6404 3.49693 27.5005 3.51271 27.3753 3.56943C27.2474 3.61995 27.1378 3.70804 27.0609 3.82209C26.984 3.93614 26.9435 4.0708 26.9447 4.20832V6.63888C26.9447 6.73097 26.9081 6.81929 26.843 6.8844C26.7779 6.94952 26.6896 6.9861 26.5975 6.9861H14.2781C12.1145 6.9861 10.0394 7.84461 8.50826 9.37315C6.97711 10.9017 6.11507 12.9754 6.11139 15.1389C6.10519 16.6858 6.54916 18.201 7.38917 19.5C7.44993 19.5996 7.53579 19.6816 7.63816 19.7377C7.74053 19.7937 7.85582 19.8219 7.9725 19.8194H8.06973C8.1995 19.8024 8.32181 19.749 8.42256 19.6655C8.52331 19.5819 8.5984 19.4716 8.63917 19.3472C9.00699 18.1827 9.73516 17.1652 10.7187 16.4412C11.7022 15.7173 12.8902 15.3245 14.1114 15.3194H26.6114C26.7035 15.3194 26.7918 15.356 26.8569 15.4211C26.922 15.4863 26.9586 15.5746 26.9586 15.6667V18.0972C26.9593 18.2343 27.0006 18.3681 27.0772 18.4818C27.1538 18.5955 27.2624 18.684 27.3892 18.7361C27.5144 18.7928 27.6542 18.8086 27.7889 18.7812C27.9236 18.7537 28.0462 18.6845 28.1392 18.5833L35.0836 11.6389C35.1501 11.5765 35.203 11.5012 35.2392 11.4176C35.2754 11.334 35.2941 11.2439 35.2941 11.1528C35.2941 11.0617 35.2754 10.9715 35.2392 10.8879C35.203 10.8043 35.1501 10.729 35.0836 10.6667L28.1253 3.72221Z"
            fill="url(#paint0_linear_1524_650)"
          />
          <path
            d="M25.889 24.6804H13.389C13.2993 24.6768 13.2145 24.6387 13.1524 24.574C13.0903 24.5092 13.0556 24.4229 13.0557 24.3332V21.9027C13.0569 21.7651 13.0164 21.6305 12.9395 21.5164C12.8626 21.4024 12.753 21.3143 12.6251 21.2638C12.4999 21.207 12.36 21.1913 12.2254 21.2187C12.0907 21.2461 11.9681 21.3154 11.8751 21.4165L4.93066 28.361C4.86423 28.4233 4.81128 28.4986 4.77508 28.5822C4.73889 28.6659 4.72021 28.756 4.72021 28.8471C4.72021 28.9382 4.73889 29.0283 4.77508 29.112C4.81128 29.1956 4.86423 29.2709 4.93066 29.3332L11.8751 36.2777C11.9371 36.3444 12.0124 36.3974 12.0961 36.4333C12.1798 36.4691 12.2701 36.4871 12.3612 36.486C12.4481 36.5057 12.5382 36.5057 12.6251 36.486C12.7611 36.4306 12.876 36.3336 12.9533 36.2088C13.0307 36.084 13.0665 35.938 13.0557 35.7915V33.361C13.0557 33.2689 13.0922 33.1806 13.1574 33.1155C13.2225 33.0504 13.3108 33.0138 13.4029 33.0138H25.7223C27.8859 33.0138 29.961 32.1553 31.4921 30.6267C33.0233 29.0982 33.8853 27.0245 33.889 24.861C33.8952 23.3141 33.4512 21.7988 32.6112 20.4999C32.5392 20.3881 32.4372 20.2989 32.3168 20.2424C32.1965 20.1859 32.0626 20.1644 31.9307 20.1804C31.8009 20.2031 31.6797 20.2602 31.5795 20.3457C31.4794 20.4312 31.404 20.542 31.3612 20.6665C30.991 21.8284 30.2618 22.843 29.2785 23.5642C28.2952 24.2855 27.1084 24.6763 25.889 24.6804Z"
            fill="url(#paint1_linear_1524_650)"
          />
        </g>
        <defs>
          <linearGradient id="paint0_linear_1524_650" x1="35.2941" y1="19.8196" x2="21.4044" y2="-5.03508" gradientUnits="userSpaceOnUse">
            <stop stop-color="#AA87FF" />
            <stop offset="1" stop-color="#7A96FF" />
          </linearGradient>
          <linearGradient id="paint1_linear_1524_650" x1="33.8891" y1="36.5008" x2="19.9737" y2="11.6381" gradientUnits="userSpaceOnUse">
            <stop stop-color="#AA87FF" />
            <stop offset="1" stop-color="#7A96FF" />
          </linearGradient>
          <clipPath id="clip0_1524_650">
            <rect width="33.3333" height="33.3333" fill="white" transform="translate(3.3335 3.3335)" />
          </clipPath>
        </defs>
      </svg>
      <h3>Requests</h3>
    </template>
    <template v-slot:content>
      <div class="breadcrumbs">
        <ul>
          <li><routerLink to="/">Home</routerLink></li>
          <li>/</li>
          <li>Requests View</li>
        </ul>
      </div>
      <div class="request-dashboard">
        <div class="request-dashboard">
          <div class="request-dashboard__top">
            <div class="request-dashboard__title">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.3">
                  <g clip-path="url(#clip0_1465_755)">
                    <path
                      d="M14.0622 1.86086C14.0156 1.81027 13.9544 1.77566 13.887 1.76194C13.8197 1.74822 13.7497 1.75611 13.6872 1.78447C13.6232 1.80973 13.5684 1.85377 13.53 1.9108C13.4915 1.96782 13.4713 2.03515 13.4719 2.10392V3.3192C13.4719 3.36524 13.4536 3.4094 13.421 3.44196C13.3885 3.47452 13.3443 3.49281 13.2983 3.49281H7.13854C6.05678 3.49281 5.01922 3.92206 4.25364 4.68633C3.48807 5.4506 3.05705 6.48743 3.05521 7.5692C3.05211 8.34264 3.27409 9.10028 3.6941 9.74975C3.72448 9.79958 3.76741 9.84055 3.81859 9.86858C3.86977 9.89661 3.92742 9.91071 3.98576 9.90947H4.03437C4.09926 9.90095 4.16042 9.87426 4.21079 9.83249C4.26117 9.79071 4.29871 9.73555 4.3191 9.67336C4.50301 9.09112 4.86709 8.58233 5.35885 8.22038C5.8506 7.85843 6.44461 7.66202 7.05521 7.65947H13.3052C13.3513 7.65947 13.3954 7.67776 13.428 7.71032C13.4605 7.74288 13.4788 7.78704 13.4788 7.83309V9.04836C13.4792 9.11691 13.4998 9.18383 13.5381 9.24068C13.5764 9.29752 13.6307 9.34176 13.6941 9.36781C13.7567 9.39617 13.8266 9.40406 13.894 9.39034C13.9613 9.37662 14.0226 9.34201 14.0691 9.29142L17.5413 5.8192C17.5745 5.78802 17.601 5.75037 17.6191 5.70857C17.6372 5.66676 17.6465 5.62169 17.6465 5.57614C17.6465 5.53059 17.6372 5.48552 17.6191 5.44371C17.601 5.40191 17.5745 5.36426 17.5413 5.33309L14.0622 1.86086Z"
                      fill="#2B2B4F"
                    />
                    <path
                      d="M12.9443 12.34H6.69425C6.64942 12.3382 6.60703 12.3191 6.57596 12.2867C6.54488 12.2544 6.52755 12.2112 6.52759 12.1664V10.9511C6.52818 10.8823 6.50793 10.815 6.4695 10.758C6.43107 10.7009 6.37627 10.6569 6.31231 10.6316C6.24972 10.6033 6.17977 10.5954 6.11244 10.6091C6.0451 10.6228 5.98381 10.6574 5.93731 10.708L2.46508 14.1803C2.43187 14.2114 2.40539 14.2491 2.3873 14.2909C2.3692 14.3327 2.35986 14.3778 2.35986 14.4233C2.35986 14.4689 2.3692 14.5139 2.3873 14.5557C2.40539 14.5975 2.43187 14.6352 2.46508 14.6664L5.93731 18.1386C5.96831 18.1719 6.00595 18.1984 6.04781 18.2164C6.08967 18.2343 6.13482 18.2433 6.18036 18.2428C6.22379 18.2526 6.26888 18.2526 6.31231 18.2428C6.38029 18.2151 6.43774 18.1665 6.47642 18.1042C6.5151 18.0418 6.53301 17.9687 6.52759 17.8955V16.6803C6.52759 16.6342 6.54588 16.59 6.57843 16.5575C6.61099 16.5249 6.65515 16.5066 6.7012 16.5066H12.8609C13.9427 16.5066 14.9802 16.0774 15.7458 15.3131C16.5114 14.5488 16.9424 13.512 16.9443 12.4303C16.9474 11.6568 16.7254 10.8992 16.3054 10.2497C16.2694 10.1938 16.2183 10.1492 16.1582 10.121C16.098 10.0927 16.0311 10.082 15.9651 10.09C15.9002 10.1013 15.8396 10.1298 15.7895 10.1726C15.7394 10.2153 15.7017 10.2708 15.6804 10.333C15.4952 10.914 15.1306 11.4212 14.639 11.7819C14.1473 12.1425 13.554 12.3379 12.9443 12.34Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1465_755">
                    <rect width="16.6667" height="16.6667" fill="white" transform="translate(1.6665 1.6665)" />
                  </clipPath>
                </defs>
              </svg>
              <h3>Data Requests</h3>
            </div>
            <p>Time to gather everything the Internet knows about you</p>
          </div>
          <div class="request-dashboard__body">
            <div class="request-dashboard__legend">
              Connected Integrations
              <a href="">Add Integration</a>
            </div>
            <div class="integration-card-list">
              <div class="integration-card">
                <div>
                  <span>
                    <img src="/img/icons/YouTube.svg" alt="" />
                  </span>
                  <label class="switch">
                    <input type="checkbox" checked />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <b>YouTube</b>
                  <span>allison@gmail.com</span>
                </div>
              </div>
              <div class="integration-card">
                <div>
                  <span>
                    <img src="/img/icons/Google.svg" alt="" />
                  </span>
                  <label class="switch">
                    <input type="checkbox" checked/>
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <b>Google</b>
                  <span>allison@gmail.com</span>
                </div>
              </div>
              <div class="integration-card">
                <div>
                  <span>
                    <img src="/img/icons/Tesla.svg" alt="" />
                  </span>
                  <label class="switch">
                    <input type="checkbox" checked/>
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <b>Tesla</b>
                  <span>allison@gmail.com</span>
                </div>
              </div>
              <div class="integration-card">
                <div>
                  <span>
                    <img src="/img/icons/Netflix.svg" alt="" />
                  </span>
                  <label class="switch">
                    <input type="checkbox" checked />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <b>Netflix</b>
                  <span>a.garrett@outlook.com</span>
                </div>
              </div>
              <div class="integration-card">
                <div>
                  <span>
                    <img src="/img/icons/Amazon.svg" alt="" />
                  </span>
                  <label class="switch">
                    <input type="checkbox" checked />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <b>Amazon</b>
                  <span>allison@gmail.com</span>
                </div>
              </div>
            </div>

            <div class="request-dashboard__legend">Requests In Progress</div>
            <div class="request-card-list">
              <RequestCard :key="15" />
            </div>

            <div class="request-dashboard__legend">Completed requests</div>
            <div class="request-card-list">
              <div class="request-card">
                <div class="request-card__top">
                  <div class="request-card__service">
                    <span>
                      <img src="/img/icons/YouTube.svg" alt="" />
                    </span>
                    <div>
                      <span> YouTube </span>
                      <small>allison@gmail.com</small>
                    </div>
                  </div>
                </div>
                <div class="request-card__bottom">
                  <div class="request-card__status">
                    <span>
                      Completed
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
                          fill="#41D3BD"
                        />
                      </svg>
                    </span>
                  </div>
                  <span> Started 25.03.2023 </span>
                </div>
              </div>
              <div class="request-card">
                <div class="request-card__top">
                  <div class="request-card__service">
                    <span>
                      <img src="/img/icons/Netflix.svg" alt="" />
                    </span>
                    <div>
                      <span> Netflix </span>
                      <small>a.garrett@outlook.com</small>
                    </div>
                  </div>
                </div>
                <div class="request-card__bottom">
                  <div class="request-card__status">
                    <span>
                      Completed
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
                          fill="#41D3BD"
                        />
                      </svg>
                    </span>
                  </div>
                  <span> Started 22.03.2023 </span>
                </div>
              </div>
              <div class="request-card">
                <div class="request-card__top">
                  <div class="request-card__service">
                    <span>
                      <img src="/img/icons/Tesla.svg" alt="" />
                    </span>
                    <div>
                      <span> Tesla </span>
                      <small>allison@gmail.com</small>
                    </div>
                  </div>
                </div>
                <div class="request-card__bottom">
                  <div class="request-card__status">
                    <span>
                      Completed
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
                          fill="#41D3BD"
                        />
                      </svg>
                    </span>
                  </div>
                  <span> Started 16.03.2023 </span>
                </div>
              </div>
              <div class="request-card">
                <div class="request-card__top">
                  <div class="request-card__service">
                    <span>
                      <img src="/img/icons/Facebook.svg" alt="" />
                    </span>
                    <div>
                      <span> Facebook </span>
                      <small>a.garrett@outlook.com</small>
                    </div>
                  </div>
                </div>
                <div class="request-card__bottom">
                  <div class="request-card__status">
                    <span>
                      Completed
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
                          fill="#41D3BD"
                        />
                      </svg>
                    </span>
                  </div>
                  <span> Started 10.03.2023 </span>
                </div>
              </div>
              <div class="request-card">
                <div class="request-card__top">
                  <div class="request-card__service">
                    <span>
                      <img src="/img/icons/Amazon.svg" alt="" />
                    </span>
                    <div>
                      <span> Amazon </span>
                      <small>allison@gmail.com</small>
                    </div>
                  </div>
                </div>
                <div class="request-card__bottom">
                  <div class="request-card__status">
                    <span>
                      Completed
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 0.5C6.51664 0.5 5.0666 0.939867 3.83323 1.76398C2.59986 2.58809 1.63856 3.75943 1.07091 5.12987C0.50325 6.50032 0.354725 8.00832 0.644114 9.46318C0.933503 10.918 1.64781 12.2544 2.6967 13.3033C3.7456 14.3522 5.08197 15.0665 6.53683 15.3559C7.99168 15.6453 9.49968 15.4968 10.8701 14.9291C12.2406 14.3614 13.4119 13.4001 14.236 12.1668C15.0601 10.9334 15.5 9.48336 15.5 8C15.5 6.01088 14.7098 4.10322 13.3033 2.6967C11.8968 1.29018 9.98913 0.5 8 0.5ZM12.3313 5.625L8.05 11.4312C8.00019 11.4991 7.93716 11.5563 7.86472 11.5992C7.79228 11.6421 7.7119 11.67 7.62843 11.6811C7.54496 11.6921 7.4601 11.6863 7.37896 11.6637C7.29782 11.6412 7.22207 11.6025 7.15625 11.55L4.1 9.10625C4.0358 9.05467 3.98242 8.99092 3.94294 8.91864C3.90345 8.84637 3.87864 8.76701 3.86993 8.68511C3.86122 8.60322 3.86878 8.52041 3.89217 8.44145C3.91557 8.36249 3.95434 8.28893 4.00625 8.225C4.11011 8.09898 4.25916 8.0186 4.42152 8.00108C4.58388 7.98356 4.74665 8.03028 4.875 8.13125L7.425 10.1687L11.325 4.875C11.4234 4.74322 11.5698 4.65553 11.7324 4.63096C11.8951 4.60639 12.0608 4.64692 12.1938 4.74375C12.2613 4.7921 12.3185 4.85341 12.362 4.92411C12.4056 4.99482 12.4346 5.07349 12.4474 5.15553C12.4602 5.23757 12.4565 5.32134 12.4366 5.40195C12.4166 5.48256 12.3808 5.55838 12.3313 5.625Z"
                          fill="#41D3BD"
                        />
                      </svg>
                    </span>
                  </div>
                  <span> Started 08.03.2023 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayoutDefault>
</template>

<script setup>
import AppLayoutDefault from "../layouts/AppLayoutDefault.vue";
import RequestCard from "../components/RequestCard.vue";

// Vue & Store
import { useHead } from "unhead";

const head = useHead({
  title: `Datapods — Requests`,
});
</script>
