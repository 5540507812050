import { ref, computed } from "vue";
import { defineStore } from "pinia";

export const useGlobalStore = defineStore("global", () => {
  const mobileMenuIsActive = ref(false);
  function toggleMobileMenu() {
    mobileMenuIsActive.value = !mobileMenuIsActive.value;
  }

  return { mobileMenuIsActive, toggleMobileMenu };
});
