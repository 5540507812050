<template>
  <div class="auth">
    <div class="auth__left">
      <div class="container">
        <routerLink to="/" href="" class="logo">
          <img src="/logo.svg" alt="" />
        </routerLink>
        <h1>
          Create <br />
          your personal <br />
          Datapod
        </h1>
        <p>Find out what personal data tech companies collect about you. Sign up, get your Datapod, request your data and start earning passive income.</p>
      </div>
    </div>
    <div class="auth__right">
      <div class="container">
        <Form class="form" :validation-schema="schema">
          <div class="form__title">Sign Up</div>
          <div class="form__inputs">
            <div class="input-group">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M17.6387 14.6664C17.1248 13.6456 15.3887 13.0067 12.979 12.1178C12.5207 11.9512 12.229 11.8817 12.0971 11.5067C12.026 11.3284 11.9991 11.1355 12.0184 10.9445C12.0377 10.7535 12.1028 10.57 12.2082 10.4095C13.1596 9.37478 13.5971 7.82617 13.5971 5.83312C13.5971 3.19423 11.7915 2.01367 9.99984 2.01367C8.20817 2.01367 6.40262 3.19423 6.40262 5.83312C6.40262 7.82617 6.87484 9.37478 7.7915 10.4095C7.89911 10.5688 7.96666 10.7518 7.98842 10.9428C8.01018 11.1338 7.98551 11.3273 7.9165 11.5067C7.78456 11.8817 7.49289 11.9512 7.03456 12.1178C4.62484 13.0067 2.86789 13.6456 2.37484 14.6664C1.93246 15.5964 1.6911 16.6092 1.6665 17.6387C1.6665 17.7308 1.70309 17.8191 1.7682 17.8842C1.83332 17.9493 1.92164 17.9859 2.01373 17.9859H17.9859C18.078 17.9859 18.1664 17.9493 18.2315 17.8842C18.2966 17.8191 18.3332 17.7308 18.3332 17.6387C18.3129 16.6102 18.0763 15.5974 17.6387 14.6664Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
              </span>
              <Field name="name" v-slot="{ field, meta, errors }" rules="required">
                <input
                  type="email"
                  v-bind="field"
                  class="form-control"
                  placeholder="Name"
                  :class="{
                    'is-success': meta.valid && meta.touched,
                    'is-danger': !meta.valid && meta.touched,
                  }"
                />
              </Field>
            </div>
            <div class="input-group">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M18.2568 5.7154C18.2362 5.70167 18.212 5.69434 18.1873 5.69434C18.1626 5.69434 18.1384 5.70167 18.1179 5.7154L11.347 12.4862C11.1705 12.6634 10.9604 12.8038 10.7292 12.8991C10.4979 12.9945 10.25 13.043 9.99982 13.0418C9.49316 13.0408 9.00704 12.8413 8.64566 12.4862L1.88178 5.74318C1.86123 5.72944 1.83706 5.72211 1.81234 5.72211C1.78762 5.72211 1.76345 5.72944 1.74289 5.74318C1.7237 5.75075 1.70668 5.76296 1.69335 5.77871C1.68002 5.79446 1.6708 5.81327 1.6665 5.83346V14.1668C1.6665 14.5351 1.81283 14.8884 2.0733 15.1489C2.33377 15.4093 2.68703 15.5557 3.05539 15.5557H16.9443C17.3126 15.5557 17.6659 15.4093 17.9263 15.1489C18.1868 14.8884 18.3331 14.5351 18.3331 14.1668V5.83346C18.3337 5.80839 18.3267 5.78372 18.3131 5.76267C18.2995 5.74162 18.2799 5.72515 18.2568 5.7154Z"
                      fill="#2B2B4F"
                    />
                    <path
                      d="M9.3818 11.7777C9.54641 11.9376 9.76688 12.0271 9.99638 12.0271C10.2259 12.0271 10.4464 11.9376 10.611 11.7777L17.3401 5.0485C17.3854 5.00295 17.4172 4.94573 17.4319 4.88321C17.4466 4.82068 17.4437 4.7553 17.4235 4.69434C17.3471 4.44434 17.1318 4.44434 16.9443 4.44434H3.05543C2.86098 4.44434 2.65265 4.44434 2.57626 4.69434C2.55603 4.7553 2.55311 4.82068 2.56782 4.88321C2.58254 4.94573 2.6143 5.00295 2.65959 5.0485L9.3818 11.7777Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
              </span>
              <input type="text" placeholder="Email" />
            </div>
            <div class="input-group">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M17.7243 13.6259L15.9492 11.8508C15.7603 11.6581 15.5348 11.5049 15.286 11.4003C15.0372 11.2958 14.77 11.2419 14.5001 11.2419C14.2302 11.2419 13.963 11.2958 13.7142 11.4003C13.4654 11.5049 13.2399 11.6581 13.051 11.8508L12.696 12.2059C10.908 10.7137 9.25674 9.06489 7.76197 7.27905L8.12423 6.91678C8.50827 6.53232 8.72398 6.01113 8.72398 5.46772C8.72398 4.92431 8.50827 4.40312 8.12423 4.01866L6.37087 2.26529C5.98276 1.8882 5.46294 1.67725 4.92181 1.67725C4.38067 1.67725 3.86085 1.8882 3.47274 2.26529L2.50187 3.23617C2.03655 3.70767 1.74681 4.32435 1.68089 4.98351C1.61498 5.64267 1.77687 6.30451 2.13961 6.85882C5.03499 11.2178 8.77179 14.9546 13.1307 17.85C13.685 18.2127 14.3469 18.3746 15.0061 18.3087C15.6652 18.2428 16.2819 17.953 16.7534 17.4877L17.7243 16.5241C17.917 16.3352 18.0702 16.1097 18.1748 15.8609C18.2793 15.6121 18.3332 15.3449 18.3332 15.075C18.3332 14.8051 18.2793 14.5379 18.1748 14.2891C18.0702 14.0403 17.917 13.8148 17.7243 13.6259Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
              </span>
              <input type="text" placeholder="Phone" />
            </div>
            <div class="input-group">
              <span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.3">
                    <path
                      d="M1.6675 17.9854L1.6675 15.9021C1.66695 15.8566 1.67593 15.8114 1.69387 15.7696C1.71181 15.7277 1.73831 15.6901 1.77167 15.6591L8.38967 9.04107C7.88161 7.93054 7.77995 6.67679 8.10245 5.49891C8.42495 4.32103 9.15111 3.29394 10.154 2.59711C11.1569 1.90028 12.3728 1.57803 13.5892 1.68668C14.8056 1.79532 15.9451 2.32796 16.8087 3.1915C17.6722 4.05504 18.2048 5.19457 18.3135 6.41096C18.4221 7.62734 18.0999 8.84323 17.4031 9.84614C16.7062 10.8491 15.6791 11.5752 14.5013 11.8977C13.3234 12.2202 12.0696 12.1186 10.9591 11.6105L9.35494 13.2216L9.80632 14.5619C9.84851 14.6788 9.85853 14.805 9.83532 14.9271C9.81211 15.0493 9.75653 15.163 9.67438 15.2563C9.5978 15.3461 9.49958 15.4149 9.38901 15.4562C9.27844 15.4976 9.15916 15.51 9.04244 15.4924L7.3619 15.2146L6.85496 15.7146L7.32023 16.7285C7.37264 16.8465 7.39111 16.9768 7.37357 17.1047C7.35604 17.2326 7.30319 17.3531 7.22096 17.4526C7.13873 17.5522 7.03038 17.6268 6.90807 17.6682C6.78576 17.7096 6.65434 17.716 6.52857 17.6868L5.27164 17.3327L4.36887 18.2285C4.33482 18.2656 4.29265 18.2943 4.24567 18.3123C4.19868 18.3304 4.14816 18.3374 4.09804 18.3327H2.01472C1.92264 18.3327 1.83432 18.2961 1.7692 18.231C1.70409 18.1658 1.6675 18.0775 1.6675 17.9854ZM14.5146 6.5272C14.7206 6.5272 14.922 6.46611 15.0933 6.35165C15.2646 6.2372 15.3981 6.07451 15.477 5.88417C15.5558 5.69383 15.5765 5.48439 15.5363 5.28233C15.4961 5.08027 15.3969 4.89466 15.2512 4.74898C15.1055 4.60331 14.9199 4.5041 14.7178 4.4639C14.5158 4.42371 14.3063 4.44434 14.116 4.52318C13.9257 4.60202 13.763 4.73553 13.6485 4.90683C13.5341 5.07813 13.473 5.27953 13.473 5.48555C13.473 5.76181 13.5827 6.02676 13.7781 6.22211C13.9734 6.41746 14.2384 6.5272 14.5146 6.5272Z"
                      fill="#2B2B4F"
                    />
                  </g>
                </svg>
              </span>
              <input type="password" placeholder="Password" />
            </div>
          </div>
          <button onclick="location.href='/requests'">Create Account</button>
          <p>By signing up, you agree to our <a href="">Terms of Service and Privacy Policy</a></p>
          <span>or</span>
          <div class="form__oauth">
            <a href="">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.6 12.2271C21.6 11.518 21.5364 10.8362 21.4182 10.1816H12V14.0544H17.3818C17.1455 15.2998 16.4364 16.3544 15.3727 17.0635V19.5816H18.6182C20.5091 17.8362 21.6 15.2725 21.6 12.2271Z"
                  fill="#4285F4"
                />
                <path
                  d="M12 22.0001C14.7 22.0001 16.9637 21.1092 18.6182 19.5819L15.3728 17.0637C14.4818 17.6637 13.3455 18.0274 12 18.0274C9.40003 18.0274 7.19094 16.2728 6.40003 13.9092H3.07275V16.491C4.71821 19.7546 8.09094 22.0001 12 22.0001Z"
                  fill="#34A853"
                />
                <path
                  d="M6.4 13.8999C6.2 13.2999 6.08182 12.6635 6.08182 11.9999C6.08182 11.3362 6.2 10.6999 6.4 10.0999V7.51807H3.07273C2.39091 8.86352 2 10.3817 2 11.9999C2 13.6181 2.39091 15.1362 3.07273 16.4817L5.66364 14.4635L6.4 13.8999Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12 5.98182C13.4728 5.98182 14.7818 6.49091 15.8273 7.47273L18.6909 4.60909C16.9546 2.99091 14.7 2 12 2C8.09094 2 4.71821 4.24545 3.07275 7.51818L6.40003 10.1C7.19094 7.73636 9.40003 5.98182 12 5.98182Z"
                  fill="#EA4335"
                />
              </svg>
            </a>
            <a href="">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.622 8.81868C19.506 8.90869 17.4578 10.0628 17.4578 12.6291C17.4578 15.5974 20.064 16.6475 20.142 16.6735C20.13 16.7375 19.728 18.1116 18.7679 19.5118C17.9118 20.7439 17.0177 21.974 15.6576 21.974C14.2974 21.974 13.9474 21.1839 12.3772 21.1839C10.8471 21.1839 10.303 22 9.05892 22C7.81479 22 6.94671 20.8599 5.94861 19.4597C4.79249 17.8156 3.8584 15.2613 3.8584 12.8371C3.8584 8.94869 6.38665 6.88649 8.8749 6.88649C10.197 6.88649 11.2991 7.75458 12.1292 7.75458C12.9193 7.75458 14.1514 6.83448 15.6556 6.83448C16.2256 6.83448 18.2738 6.88649 19.622 8.81868ZM14.9415 5.18832C15.5636 4.45024 16.0036 3.42614 16.0036 2.40204C16.0036 2.26003 15.9916 2.11601 15.9656 2C14.9535 2.038 13.7494 2.67407 13.0233 3.51615C12.4533 4.16422 11.9212 5.18832 11.9212 6.22642C11.9212 6.38244 11.9472 6.53845 11.9592 6.58846C12.0232 6.60046 12.1272 6.61446 12.2312 6.61446C13.1393 6.61446 14.2814 6.0064 14.9415 5.18832Z"
                  fill="black"
                />
              </svg>
            </a>
            <a href="">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 12C22 6.47656 17.5234 2 12 2C6.47656 2 2 6.47656 2 12C2 16.9922 5.65625 21.1289 10.4375 21.8789V14.8906H7.89844V12H10.4375V9.79688C10.4375 7.29102 11.9297 5.90625 14.2148 5.90625C15.3086 5.90625 16.4531 6.10156 16.4531 6.10156V8.5625H15.1914C13.9492 8.5625 13.5625 9.33398 13.5625 10.125V12H16.3359L15.8926 14.8906H13.5625V21.8789C18.3438 21.1289 22 16.9922 22 12Z"
                  fill="#1877F2"
                />
                <path
                  d="M15.8926 14.8906L16.3359 12H13.5625V10.125C13.5625 9.33398 13.9492 8.5625 15.1914 8.5625H16.4531V6.10156C16.4531 6.10156 15.3086 5.90625 14.2148 5.90625C11.9297 5.90625 10.4375 7.29102 10.4375 9.79688V12H7.89844V14.8906H10.4375V21.8789C10.9473 21.959 11.4688 22 12 22C12.5312 22 13.0527 21.959 13.5625 21.8789V14.8906H15.8926Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <p>Already have an account? <a href="">Log In</a></p>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup>

import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required(),
});
</script>
