import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";
import RequestsView from "../views/RequestsView.vue";
import AuthView from "../views/AuthView.vue";
import LocationView from "../views/LocationView.vue";
import CreateRequestView from "../views/CreateRequestView.vue";
import SharingView from "../views/SharingView.vue";
import SharingSummaryView from "../views/SharingSummaryView.vue";
import SharingSuccessView from "../views/SharingSuccessView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "index",
      component: IndexView,
    },
    {
      path: "/requests",
      name: "requests",
      component: RequestsView,
    },
    {
      path: "/auth",
      name: "auth",
      component: AuthView,
    },
    {
      path: "/location",
      name: "location",
      component: LocationView,
    },
    {
      path: "/create",
      name: "create",
      component: CreateRequestView,
    },
    {
      path: "/sharing",
      name: "sharing",
      component: SharingView,
    },
    {
      path: "/summary",
      name: "summary",
      component: SharingSummaryView,
    },
    {
      path: "/success",
      name: "success",
      component: SharingSuccessView,
    },
  ],
});

export default router;
