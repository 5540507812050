<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false" :class="{ open: open }">
    <div class="selected" @click="open = !open">
      <span>{{ selected.text }}</span>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
const emit = defineEmits(["input"]);
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  default: {
    type: Object,
    required: false,
    default: null,
  },
  tabindex: {
    type: Number,
    required: false,
    default: 0,
  },
});
const selected = ref(props.default ? props.default : props.options.length > 0 ? props.options[0] : null);
const open = ref(false);
onMounted(() => {
  emit("input", selected.value);
});
</script>

<style scoped></style>
