<template>
  <div class="modal">
    <div class="modal__dialog">
      <div class="modal__wrapper">
        <div class="modal__top">
          <slot name="top"></slot>
        </div>
        <div class="modal__body">
          <slot name="body"></slot>
        </div>
        <div class="modal__bottom">
          <slot name="bottom"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
