// Vue
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createHead } from "unhead";
// Plugins
import SliderImitatePlugin from "./plugins/SliderImitatePlugin";
// Styles
import "./assets/scss/style.scss";
import "nouislider/dist/nouislider.css";
import "swiper/css";
// Libraries
import { register } from "swiper/element/bundle";

// Create a global head instance
const head = createHead();

// register Swiper custom elements
register();

// Use
const app = createApp(App);
app.use(SliderImitatePlugin);
app.use(createPinia());
app.use(router);

//  Mount
app.use(head);
app.mount("#app");
